<template>
  <van-tabbar route active-color="#15bfc7">
    <van-tabbar-item icon="apps-o" to="/wlms/">首页</van-tabbar-item>
    <!-- <van-tabbar-item icon="aim" to="/wlms/analyse">走势</van-tabbar-item> -->
    <van-tabbar-item icon="bullhorn-o" to="/wlms/warning">告警</van-tabbar-item>
    <van-tabbar-item icon="setting-o" to="/wlms/about">关于</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabbar',
  props: {
    parent: String
  },
  data(){
    return{
      // total:window.sessionStorage.getItem('onceAlarm') != '0' ? window.sessionStorage.getItem('onceAlarm') : ''
    }
  },
  mounted(){
    // !window.sessionStorage.getItem('onceAlarm') ?  this.alarmNum() : void 0 ;
  },
  methods:{
    // alarmNum(){
    //   let code = window.sessionStorage.getItem('bridgeCode');
    //   this.$api.BRIDGE.getAlarmNum({briCode:code}).then(d=>{
    //       d == '0' ? this.total = '' : this.total = d;
    //       window.sessionStorage.setItem('onceAlarm',d);
    //   })
    // }
  }
}
</script>
